import React, { Component, useState, useEffect, useRef  } from 'react';

import './UiSelect.scss';

class UiSelect extends Component {

  state = {
    selectValue: '166',
    currentIndex: 0,
  }

  constructor(props) {
    super(props);
  }

 
  componentWillMount() {
    if (this.props.selectValue != undefined && this.props.selectValue) {
      this.setState({ selectValue: this.props.selectValue });
    }
  }
  componentDidUpdate(nProps, nState) {

    if (nProps.clear != undefined && nProps.clear) {
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if (this.props.selectValue != undefined && this.props.selectValue && nProps.selectValue != this.state.selectValue) {
      this.setState({ selectValue: this.props.selectValue });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ selectValue: val });
    this.props.callBack(val );
  }

 
  render() {
    var SelectItems = this.props.selectItems.filter((obj1, i, arr) => 
      arr.findIndex(obj2 => (obj2.option === obj1.option)) === i
    ).map((item, index) => {
      return (
        <div key={index} onClick={() => this.setValue(item) } className="select-option">{item.option}{this.props.showPrice ? ` (${item.value}р)` : ""}</div>
      );
    });
    

 
    return (
      <div className="select" onClick={() => this.setState({ selectOpen: !this.state.selectOpen })}   >
        <div className="select-inner">
          <p>{this.props.selectedText}</p>
          <img src={require('../../assets/images/ui/select-arrow.svg')} />
        </div>
        <div className={"select-list" + (this.state.selectOpen ? " active" : "")}>
          {SelectItems}
        </div>  
      </div>
    );
  }
}
export default UiSelect;

